import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import LinkMap from "../components/linkMap";
import NextArticle from "../components/nextArticle";

import styled from "styled-components";
import Container from "../components/Container";
import Header from "../components/header";
import Sticky from "react-stickynode";
import { css } from "emotion";
import useMatchMedia from "../components/useMatchMedia";

const Content = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  justify-content: space-between;

  p,
  li {
    font-size: 20px;
  }

  a {
    color: #4c3e9c;
  }

  img {
    margin: 1rem 0;
  }
`;

const MainHeading = styled.h1`
  text-align: left;
  font-size: 44px;
  margin-bottom: 1rem;
`;

const StyledSubHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const StyledZIndex = styled.div`
  z-index: 2;
`;

const ContentRestriction = styled.div`
  max-width: 732px;
  margin-bottom: 80px;
  min-height: 50vh;
`;

const StyledStooper = styled.div``;

export default ({ data }) => {
  return (
    <Layout>
      <StyledSubHeader>
        <Container>
          <Header dark></Header>
        </Container>
      </StyledSubHeader>
      <Container>
        <MainHeading>Kontakt</MainHeading>
        <Content>
          <ContentRestriction>
            <p>
              Rádi Vám pomůžeme se vším co se týká Shopify a stavění e-shopu, na
              platformě máme přes 4 roky zkušeností.
            </p>
            <h2>
              <a href="mailto:info@eshopcesky.cz">info@eshopcesky.cz</a>
            </h2>
          </ContentRestriction>
        </Content>
      </Container>
    </Layout>
  );
};
